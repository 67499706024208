<template>
    <div class="scheduling">
        <div class="tabs" style="margin-bottom: 20px">
            <ul>
                <li @click="_route">排班设置</li>
                <li class="active">排班管理</li>
            </ul>
        </div>
        <div class="content-warp">
            <div class="content-left">
                <p class="company">公司组织架构</p>
                <a-tree v-if="treeData" :treeData="treeData" @select="_treeSelect"/>
            </div>
            <div class="content-right">
                <div class="seeting">班级设置</div>
                <div class="flex">
                    <span class="class-explain">班级说明:</span>
                    <div style="flex:1;margin-right: 200px">
                        <a-tag
                            v-if="classExplain"
                            v-for="(item, index) in classExplain"
                            style="margin-bottom: 10px"
                            :color="item.Color"
                        >
                            {{ item.Name }} ({{ item.StartTime }}-{{ item.EndTime }})
                        </a-tag>
                    </div>
                </div>

                <div class="search" style="display: block;margin-top: 20px">
                    <span class="search-title">时间</span>
                    <a-month-picker
                        class="search-style"
                        placeholder="请选择时间"
                        :value="timeValue"
                        @change="_changrMonth"
                    />
                    <span class="search-title">员工姓名</span>
                    <a-input
                        class="search-style"
                        v-model="SearchName"
                        placeholder="请输入员工姓名"
                    />
                    <a-button class="search-style" @click="_clear">重置</a-button>
                    <a-button class="search-style" type="primary" @click="_search"
                    >查询
                    </a-button
                    >
                </div>

                <div style="color:red;margin-bottom: 20px">
                    如果员工某个月有手动排班记录,系统就不会自动生成该员工对应月份的排班信息
                </div>
                <!--<div style="width: 1300px;" v-if="tableData">-->
                <div :style="'width:' + pageWidth + 'px'" v-if="tableData">
                    <a-table
                        bordered
                        :data-source="tableData"
                        :pagination="false"
                        :scroll="{ x: 2400, y: 300 }"
                        :customRow="rowClick"
                    >
                        <a-table-column
                            style="padding: 0"
                            key="UserName"
                            fixed="left"
                            :width="210"
                            :height="42"
                            title="姓名"
                            data-index="UserName"
                        >
                            <div slot-scope="text, record">
                <span
                    @click="
                    _popoverAll(record.UserName, record.UserId, 'setName')
                  "
                >{{ record.UserName }}</span
                >
                                <a-popover
                                    v-if="UserName == record.UserName && setName == 'setName'"
                                    v-model="visible"
                                >
                                    <template slot="content">
                                        <div class="popover-content">
                                            <a-tag
                                                v-for="(item, key) in classExplain"
                                                style="margin-bottom: 10px;display: block;width:175px ;overflow-x: hidden;  word-break: keep-all;"
                                                :color="item.Color"
                                            >
                        <span
                            @click="_seetingAll(item.Id, record.UserId)"
                            style="width: 100%;display: block;overflow-x: hidden;  word-break: keep-all;"
                        >{{ item.Name }}({{ item.StartTime }}-{{
                                item.EndTime
                            }})</span
                        >
                                            </a-tag>
                                        </div>
                                        <a-button
                                            @click="_emptyAll(record.UserId)"
                                            style="display: block;margin-bottom: 10px;margin-top: 10px"
                                        >清空
                                        </a-button
                                        >
                                        <a-button
                                            style="display: block;margin-bottom: 10px"
                                            @click="_setTimeAll(record.UserId, record.UserName)"
                                        >自定义时间
                                        </a-button
                                        >
                                    </template>
                                    <a slot="content" @click="hide">关闭</a>
                                </a-popover>
                            </div>
                        </a-table-column>

                        <a-table-column
                            style="padding: 0"
                            :width="70"
                            v-for="(item, index) in ClassTableHead"
                            :key="'day' + index"
                            data-index="'day'+index"
                        >
              <span
                  style="position:absolute;top:0;left:0;text-align:center;width:100%;height:100%;padding-top:10px;font-size: 15px;"
                  slot="title"
                  :class="item == '六' || item == '日' ? 'active' : ''"
                  @click="_headPopover(index, item, 'headTitle')"
              >{{ index }}<br/>{{ item }}
                <a-popover
                    v-if="headIndex == index && setName == 'headTitle'"
                    v-model="visible"
                >
                  <template slot="content">
                    <div class="popover-content">
                      <a-tag
                          v-for="(item, key) in classExplain"
                          style="margin-bottom: 10px;display: block;width: 100%"
                          :color="item.Color"
                      >
                        <span
                            @click="_seetingHead(item.Id, index)"
                            style="width: 100%;display: block"
                        >{{ item.Name }}({{ item.StartTime }}-{{
                                item.EndTime
                            }})</span
                        >
                      </a-tag>
                    </div>

                    <a-button
                        @click="_emptyHead(index)"
                        style="display: block;margin-bottom: 10px;margin-top: 10px"
                    >清空</a-button
                    >

                    <a-button
                        style="display: block;margin-bottom: 10px"
                        @click="_setTimeHead(index)"
                    >自定义时间</a-button
                    >
                  </template>
                  <a slot="content" @click="hide">关闭</a>
                </a-popover>
              </span>

                            <div
                                slot-scope="text, record"
                                style="position: absolute;top:0;left:0;width:100%;height: 100%"
                            >
                <span
                    v-if="
                    record['day' + index] &&
                      record['day' + index]['ClassTypeName']
                  "
                    :style="
                    record['day' + index]['Color']
                      ? 'position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);border-radius:2px;width:92%;height:92%;line-height:42px;text-align: center;font-size:14px;color:#ffffff;background:' +
                        record['day' + index]['Color']
                      : 'position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);border-radius:2px;width:92%;height:92%;line-height:42px;text-align: center;font-size:14px;'
                  "
                    @click="_popover(index, record.UserId)"
                >
                  <a-tooltip
                      :title="
                      record['day' + index]['CustomStartTime']
                        ? record['day' + index]['CustomStartTime'] +
                          '-' +
                          record['day' + index]['CustomEndTime']
                        : record['day' + index]['ClassTypeName'] +
                          '(' +
                          (record['day' + index]['StartTime'] +
                            '-' +
                            record['day' + index]['EndTime']) +
                          ')'
                    "
                  >
                    {{ record['day' + index]['ClassTypeName'] }}
                  </a-tooltip>
                </span>
                                <span
                                    v-else
                                    @click="_popover(index, record.UserId)"
                                    style="position: absolute;top:0;left:0;width:100%;height:100%"
                                >&nbsp;</span
                                >
                                <a-popover
                                    v-if="
                    popoverIndex == index && userId == record.UserId && !setName
                  "
                                    v-model="visible"
                                    trigger="click"
                                >
                                    <template slot="content">
                                        <div class="popover-content">
                                            <a-tag
                                                v-for="(item, key) in classExplain"
                                                style="margin-bottom: 10px;display: block;width: 100%"
                                                :color="item.Color"
                                            >
                        <span
                            @click="
                            _seeting(
                              item.Id,
                              index,
                              record.UserId,
                              record['day' + index]['SchedulId']
                            )
                          "
                            style="width: 100%;display: block"
                        >{{ item.Name }}({{ item.StartTime }}-{{
                                item.EndTime
                            }})</span
                        >
                                            </a-tag>
                                        </div>

                                        <a-button
                                            @click="_empty(record['day' + index]['SchedulId'])"
                                            style="display: block;margin-bottom: 10px;margin-top: 10px"
                                        >清空
                                        </a-button
                                        >

                                        <a-button
                                            style="display: block;margin-bottom: 10px"
                                            @click="
                        _setTime(
                          record['day' + index]['SchedulId'],
                          index,
                          record.UserId,
                          record.UserName
                        )
                      "
                                        >自定义时间
                                        </a-button
                                        >
                                    </template>
                                    <a slot="content" @click="hide">关闭</a>
                                </a-popover>
                            </div>
                        </a-table-column>
                    </a-table>
                </div>
                <a-pagination
                    v-if="count"
                    style="margin-top: 35px;text-align: right;margin-bottom: 35px"
                    @change="ChangePage"
                    :pageSize="pagesize"
                    :total="count"
                    show-less-items
                />

                <div
                    :style="{ width: pageWidth + 'px', marginBottom: '60px' }"
                    v-if="reportData"
                >
                    <a-table
                        bordered
                        :data-source="reportData"
                        :pagination="false"
                        :scroll="{ x: 2000, y: 300 }"
                    >
                        <a-table-column
                            :width="140"
                            style=" word-break: keep-all;text-align: center;overflow-x:hidden;"
                            key="classTypeName"
                            title="班次名称"
                            fixed="left"
                            data-index="classTypeName"
                        >
                        </a-table-column>
                        <a-table-column
                            :width="60"
                            v-for="(item, index) in ClassTableHead"
                            :key="'day' + index"
                            data-index="'day'+index"
                            style="width: 120px"
                        >
              <span
                  slot="title"
                  :class="item == '六' || item == '日' ? 'active' : ''"
              >{{ index }}<br/>{{ item }}</span
              >
                            <div
                                slot-scope="text, record"
                                style="width: 100%;text-align: center"
                            >
                                {{ record['day' + index] }}
                            </div>
                        </a-table-column>
                    </a-table>
                </div>
            </div>
        </div>
        <!--自定义时间-->
        <a-drawer
            :title="visibleTitle"
            width="520"
            :closable="false"
            :visible="visibleTime"
            @close="onClose"
        >
            <div class="group">
                <div class="group-label">上班</div>
                <a-time-picker
                    format="HH:mm"
                    :value="timeValue2"
                    @change="onChangeStart"
                />
            </div>
            <div class="group">
                <div class="group-label">下班</div>
                <a-time-picker
                    :value="timeValue1"
                    format="HH:mm"
                    @change="onChangeEnd"
                />
            </div>

            <div
                :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button @click="_toSetTimeAll" type="primary">
                    确认
                </a-button>
            </div>
        </a-drawer>
        <div v-show="spinning" style="position:fixed;left:50%;">
            <a-spin style="margin: 150px 0%;">
                <a-icon
                    slot="indicator"
                    type="loading"
                    :spinning="spinning"
                    style="font-size: 44px;color:red;"
                    spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
export default {
    name: 'scheduling',
    data() {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            treeData: '',
            partyid: '',
            classExplain: '',
            timeValue: undefined,
            SearchMonth: '',
            tableData: '',
            scroll: {x: 1730, y: 500},
            ClassTableHead: '',
            reportData: '',
            visible: false,
            popoverIndex: '',
            userId: '',
            UserName: '',
            visibleTime: false,
            visibleTitle: '',
            UserIdTime: '',
            CustomStartTime: '',
            CustomEndTime: '',
            TimeDay: '',
            TimeId: '',
            setName: '',
            timeValue1: undefined,
            timeValue2: undefined,
            SearchName: '',
            pageNo: 1, //页码数
            pagesize: 20,
            count: '',
            spinning: true,
            headIndex: '',
            pageWidth: ''
        }
    },
    mounted() {
        //组织架构
        this.$axios.get(1072, {}, res => {
            if (res.data.list) {
                this.treeData = res.data.list
                window.sessionStorage.setItem(
                    'get_org_list',
                    JSON.stringify(res.data.list)
                )
            } else {
                this.$message.error('获取数据失败!')
            }
        })
        this.$axios.get(
            1105,
            {
                pagesize: 10000
            },
            res => {
                if (res.data.code == 1) {
                    this.classExplain = res.data.data
                }
            }
        )
        this.pageWidth = document.documentElement.clientWidth - 440
    },
    created() {
        this._ClassTableHead()
        this._user_list()
        this._schedul_report()
    },
    methods: {
        rowClick(record, index) {
            return {
                on: {
                    click: event => {
                        console.log($(event.target).find('span')[0])
                    }
                }
            }
        },
        _search() {
            this._user_list()
            this._schedul_report()
            this._ClassTableHead()
        },
        _user_list() {
            this.spinning = true
            let self = this
            this.$axios.get(
                1108,
                {
                    UserId: self.userinfo.uid,
                    YearMonth: self.SearchMonth,
                    OrgId: self.partyid,
                    UserName: self.SearchName,
                    pagesize: self.pagesize,
                    pageNo: self.pageNo
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        self.tableData = res.data.data
                        self.count = res.data.total
                    } else {
                        self.tableData = ''
                        self.count = ''
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        ChangePage(value) {
            this.pageNo = value
            this._user_list()
            this._schedul_report()
        },
        _schedul_report() {
            let self = this
            this.$axios.get(
                1109,
                {
                    UserId: self.userinfo.uid,
                    YearMonth: self.SearchMonth,
                    OrgId: self.partyid,
                    UserName: self.SearchName
                },
                res => {
                    if (res.data.code == 1) {
                        self.reportData = res.data.data
                    } else {
                        self.reportData = ''
                    }
                }
            )
        },
        _ClassTableHead() {
            let self = this
            this.$axios.get(
                1107,
                {
                    month: self.SearchMonth
                },
                res => {
                    self.ClassTableHead = res.data
                }
            )
        },
        _clear() {
            this.SearchName = ''
            this.partyid = ''
            this.pageNo = ''
            this.timeValue = undefined
            this.SearchMonth = ''
        },
        _treeSelect(key, e) {
            console.log(e)
            this.partyid = e.selectedNodes[0].data.props.value
            this._user_list()
            this._schedul_report()
        },
        _changrMonth(value) {
            this.timeValue = value
            let date1 = new Date(value._d)
            this.SearchMonth =
                date1.getFullYear() +
                '-' +
                (date1.getMonth() + 1 < 10
                    ? '0' + (date1.getMonth() + 1)
                    : date1.getMonth() + 1)
        },
        onChangeStart(time, timeString) {
            this.timeValue2 = time
            this.CustomStartTime = timeString
        },
        onChangeEnd(time, timeString) {
            this.timeValue1 = time
            this.CustomEndTime = timeString
        },
        //表头操作
        _headPopover(key, item, headTitle) {
            this.setName = headTitle
            this.headIndex = key
            console.log(this.headIndex)
            this.visible = true
        },
        _seetingHead(id, key) {
            let userId = []
            if (this.tableData) {
                for (var i = 0; i < this.tableData.length; i++) {
                    userId[i] = this.tableData[i]['UserId']
                }
            }
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() +
                    '-' +
                    (month >= 10 ? month : '0' + month) +
                    '-' +
                    (key >= 10 ? key : '0' + key)
            } else {
                TheDate = this.SearchMonth + '-' + (key >= 10 ? key : '0' + key)
            }
            let self = this
            this.$axios.post(
                1114,
                {
                    UserId: userId.length > 0 ? JSON.stringify(userId) : '',
                    TheDate: TheDate,
                    ClassTypeId: id
                },
                res => {
                    if (res.data.code == 1) {
                        self.headIndex = ''
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                        this.$message.success(res.data.msg)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        _emptyHead(key) {
            let userId = []
            if (this.tableData) {
                for (var i = 0; i < this.tableData.length; i++) {
                    userId[i] = this.tableData[i]['UserId']
                }
            }
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() +
                    '-' +
                    (month >= 10 ? month : '0' + month) +
                    '-' +
                    (key >= 10 ? key : '0' + key)
            } else {
                TheDate = this.SearchMonth + '-' + (key >= 10 ? key : '0' + key)
            }
            let self = this
            this.$axios.post(
                1115,
                {
                    UserId: userId.length > 0 ? JSON.stringify(userId) : '',
                    TheDate: TheDate
                },
                res => {
                    if (res.data.code == 1) {
                        self.headIndex = ''
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                        this.$message.success(res.data.msg)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        _setTimeHead(key) {
            console.log(this.setName)
            this.TimeDay = key
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() +
                    '-' +
                    (month >= 10 ? month : '0' + month) +
                    '-' +
                    (key >= 10 ? key : '0' + key)
            } else {
                TheDate = this.SearchMonth + '-' + (key >= 10 ? key : '0' + key)
            }
            this.visibleTitle = TheDate
            this.visibleTime = true
        },
        //自定义时间
        onClose() {
            this.visibleTitle = ''
            this.UserIdTime = ''
            this.CustomEndTime = ''
            this.CustomStartTime = ''
            this.TimeDay = ''
            this.TimeId = ''
            this.timeValue1 = undefined
            this.timeValue2 = undefined
            this.visibleTime = false
        },
        _setTime(Id, day, UserId, visibleTitle) {
            this.visibleTitle = visibleTitle
            this.UserIdTime = UserId
            this.TimeDay = day
            this.TimeId = Id
            this.visibleTime = true
        },
        _setTimeAll(UserIdTime, visibleTitle) {
            this.UserIdTime = UserIdTime
            this.visibleTitle = visibleTitle
            this.visibleTime = true
        },
        _toSetTimeAll() {
            if (!this.CustomStartTime) {
                this.$message.error('请输入上班时间')
                return false
            }
            if (!this.CustomEndTime) {
                this.$message.error('请输入下班时间')
                return false
            }
            let formatDate1 = this.CustomStartTime.split(':')
            let intStartTime = formatDate1[0] * 3600 + formatDate1[1] * 60
            let formatDate2 = this.CustomEndTime.split(':')
            let intStopTime = formatDate2[0] * 3600 + formatDate1[1] * 60
            if (intStartTime >= intStopTime) {
                this.$message.error('上班时间不能大于下班时间')
                return false
            }
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1

                if (this.setName == 'setName') {
                    TheDate =
                        nowDate.getFullYear() + '-' + (month >= 10 ? month : '0' + month)
                } else {
                    TheDate =
                        nowDate.getFullYear() +
                        '-' +
                        (month >= 10 ? month : '0' + month) +
                        '-' +
                        (this.TimeDay >= 10 ? this.TimeDay : '0' + this.TimeDay)
                }
            } else {
                if (this.setName == 'setName') {
                    TheDate = this.SearchMonth
                } else {
                    TheDate =
                        this.SearchMonth +
                        '-' +
                        (this.TimeDay >= 10 ? this.TimeDay : '0' + this.TimeDay)
                }
            }
            let self = this
            this.spinning = true
            if (this.setName == 'headTitle') {
                let userId = []
                if (this.tableData) {
                    for (var i = 0; i < this.tableData.length; i++) {
                        userId[i] = this.tableData[i]['UserId']
                    }
                }
                //批量修改某一天的时间
                this.$axios.post(
                    1114,
                    {
                        UserId: userId.length > 0 ? JSON.stringify(userId) : '',
                        CustomStartTime: self.CustomStartTime,
                        CustomEndTime: self.CustomEndTime,
                        TheDate: TheDate
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.headIndex = ''
                            self.hide()
                            self.onClose()
                            self._schedul_report()
                            self._user_list()
                            this.$message.success(res.data.msg)
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            } else if (this.setName == 'setName') {
                //批量修改某个月时间
                this.$axios.post(
                    1112,
                    {
                        ClassTypeId: '',
                        UserId: self.UserIdTime,
                        Month: TheDate,
                        CustomStartTime: self.CustomStartTime,
                        CustomEndTime: self.CustomEndTime
                    },
                    res => {
                        self.spinning = false
                        if (res.data.code == 1) {
                            self.hide()
                            self._user_list()
                            self._schedul_report()
                            self.onClose()
                            this.$message.success(res.data.msg)
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            } else {
                //单个修改时间
                this.$axios.post(
                    1111,
                    {
                        Id: self.TimeId,
                        ClassTypeId: '',
                        UserId: self.UserIdTime,
                        TheDate: TheDate,
                        CustomStartTime: self.CustomStartTime,
                        CustomEndTime: self.CustomEndTime
                    },
                    res => {
                        self.spinning = false
                        if (res.data.code == 1) {
                            this.$message.success(res.data.msg)
                            self.hide()
                            self._user_list()
                            self._schedul_report()
                            self.onClose()
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            }
        },
        //批量处理
        _popoverAll(UserName, userId, setName) {
            this.setName = setName
            this.UserName = UserName
            this.visible = true
        },
        _seetingAll(ClassTypeId, UserId) {
            console.log(ClassTypeId, UserId)
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() + '-' + (month >= 10 ? month : '0' + month)
            } else {
                TheDate = this.SearchMonth
            }
            let self = this
            this.spinning = true
            this.$axios.post(
                1112,
                {
                    ClassTypeId: ClassTypeId,
                    UserId: UserId,
                    Month: TheDate
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                        this.$message.success(res.data.msg)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },

        _emptyAll(UserId) {
            let self = this
            let TheDate = ''
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() + '-' + (month >= 10 ? month : '0' + month)
            } else {
                TheDate = this.SearchMonth
            }
            this.spinning = true
            this.$axios.get(
                1113,
                {
                    UserId: UserId,
                    Month: TheDate
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                        this.$message.success(res.data.msg)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        _route() {
            this.$router.push({path: '/schedulSeting'})
        },
        _empty(id) {
            let self = this
            this.spinning = true
            this.$axios.get(
                1110,
                {
                    UserId: self.userinfo.uid,
                    Id: id
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg)
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        _seeting(id, day, userid, SchedulId) {
            let TheDate = ''
            let self = this
            if (!this.SearchMonth) {
                let nowDate = new Date()
                let date = {
                    year: nowDate.getFullYear(),
                    month: nowDate.getMonth() + 1,
                    date: nowDate.getDate()
                }
                let month = nowDate.getMonth() + 1
                TheDate =
                    nowDate.getFullYear() +
                    '-' +
                    (month >= 10 ? month : '0' + month) +
                    '-' +
                    (day >= 10 ? day : '0' + day)
            } else {
                TheDate = this.SearchMonth + '-' + (day >= 10 ? day : '0' + day)
            }
            this.spinning = true
            this.$axios.post(
                1111,
                {
                    Id: SchedulId,
                    ClassTypeId: id,
                    UserId: userid,
                    TheDate: TheDate
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        this.$message.success(res.data.msg)
                        self.hide()
                        self._schedul_report()
                        self._user_list()
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            )
        },
        hide() {
            this.setName = ''
            this.visible = false
        },
        _popover(index, userId) {
            this.setName = ''
            this.visible = true
            this.popoverIndex = index
            this.userId = userId
        }
    }
}
</script>

<style scoped lang="less">
td {
    position: relative;
}

* {
    font-family: Microsoft YaHei;
}

.scheduling {
    /*background: #ffffff;*/
    margin-top: 15px;
    min-height: 800px;
    /*padding: 15px;*/
}

.active {
    color: #ff0000;
}

.tabs {
    border-bottom: 1px solid #ccd1dc;
    border-left: 1px solid #ccd1dc;
    border-top-left-radius: 5px;

    ul {
        li {
            display: inline-block;
            width: 85px;
            padding: 6px 0;
            text-align: center;
            border: 1px solid #ccd1dc;
            border-bottom: none;
            cursor: pointer;
            border-left: 0;

            &:first-child {
                border-top-left-radius: 5px;
            }

            &:last-child {
                border-top-left-radius: 0;
                border-top-right-radius: 5px;
            }
        }

        .active {
            background: #1890ff;
            color: #fff;
            border: 1px solid #1890ff;
        }
    }
}

.content-warp {
    display: flex;
    min-height: 800px;
}

.content-left {
    width: 200px;
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    margin-right: 25px;
    box-shadow: 0px 3px 4px rgba(14, 25, 36, 0.09);

    .company {
        color: #242424;
        font-size: 14px;
    }
}

.content-right {
    flex: 1;
    background: #ffffff;
    padding: 15px;
    /*border: 1px solid #DFE0E6;*/
    border-radius: 5px;
    box-shadow: 0px 3px 4px rgba(14, 25, 36, 0.09);

    .seeting {
        width: 490px;
        border-bottom: 1px solid rgba(248, 249, 250, 1);
        color: rgba(102, 102, 102, 1);
        font-size: 13px;
        height: 35px;
        line-height: 35px;
        margin-bottom: 10px;
    }

    .class-explain {
        width: 65px;
        font-size: 13px;
        font-weight: 400;
        height: 32px;
        /*line-height:32px;*/
        color: rgba(102, 102, 102, 1);
        margin-right: 5px;
    }
}

.search {
    margin-bottom: 20px;

    .search-style {
        display: inline-block;
        width: 120px;
        margin-right: 15px;
        margin-bottom: 10px;
    }

    .search-title {
        margin-right: 5px;
    }
}

.group {
    display: flex;
    margin-bottom: 20px;

    .group-label {
        width: 80px;
        height: 32px;
        line-height: 32px;
        font-size: 13px;
        color: rgba(102, 102, 102, 1);
    }

    .group-item {
        flex: 1;
    }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 0 !important;
}

.popover-content {
    height: 100px;
    overflow: auto;
}
</style>
